.side-nav {
    background-color: var(--side-nav-bg-color);
    box-shadow: var(--side-nav-box-shadow);
}



// @media (max-width: 576px) {
//     .side-nav-item {
//         align-items: center;
//         display: flex;
//         justify-content: space-between;
//         padding: 15px 10px;
//         width: 100%;
//         height: 70px;
//         min-height: 40px;
//         overflow: hidden;
//         font-size: 1rem;
    
//         cursor: pointer; // This needs to be based a flag
    
//         .side-nav-text {
//             padding-left: 10px;
//             opacity: 1;
//             min-width: 100px;
//             width: 100%;
    
//             div {
//                 min-width: 102px;
//                 width: 100%
//             }
//         }

//         &.closed {
//             .side-nav-text {
//                 opacity: 0;
//             }
    
//             .card-actions {
//                opacity: 0;
//                font-size: inherit
//             }
//         }
    
//         span {
//             &:last-child {
//                 flex-grow: 1;
//                 justify-content: end;
//             }
//         }
//     }
// }
